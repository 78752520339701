<template>
  <div>
    <v-form @submit.prevent="onBeforeSubmit" ref="formRef">
      <!-- {{currentItem}} -->
      <v-row dense class="pt-4 pb-0 mb-0">
          <div class="center-text h1"> Id: {{currentItem.id}} - {{currentItem.matmed.nome_substancia}} {{ currentItem.nomecomercial  ? ` - "${currentItem.nomecomercial}" ` : ''}} 
          </div>
      </v-row>
      <v-row dense>
        <v-col>
          <v-select outlined required label="Status" v-model="currentItem.ativo" item-value="value"
            item-text="nome" :items="ativoOptions"></v-select>
        </v-col> 
        <v-col v-if="currentItem.aditivo_matmed">
          <v-select outlined required label="Aditivo" v-model="currentItem.aditivo_matmed" item-value="value"
            item-text="nome" :items="statusOptions" disabled></v-select>
        </v-col> 
        <v-col  v-if="currentItem.aditivo_matmed">
          <v-text-field label="Nr do Aditivo" outlined v-model="currentItem.nr_aditivo" type="Number"  disabled> </v-text-field>
        </v-col>
        <v-col  v-if="currentItem.aditivo_matmed">
          <v-text-field label="Data do Aditivo" outlined v-model="currentItem.data_aditivo" type="date"  disabled> </v-text-field>
        </v-col>
        <v-col>
          <v-select outlined label="Frequência" v-model="currentItem.frequencia" :items="frequencies"
          item-value="id" item-text="nome" required :rules="requiredField" ></v-select>
        </v-col>
        <v-col>
          <v-text-field label="Data de Início" outlined v-model="formattedDataInicio" type="date" > </v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Data de Término" outlined v-model="formattedDataFim" type="date" > </v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Código" outlined v-model="currentItem.codigo" type="Number" > </v-text-field>
        </v-col>
        <v-col class="mt-4" cols="2">
            <span >Código com {{ currentItem.codigo ? currentItem.codigo.toString().length : 0 }} dígitos</span>
          </v-col>
      </v-row>
      <v-row dense>


      <v-col>
        <v-autocomplete v-model="currentItem.tabela" validate-on-blur :items="tableValues" item-value="id"
          :item-text="tabelaText" return-object outlined dense >
          <template v-slot:label>
            <span>Código da Tabela - Descrição <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col>
        <v-autocomplete v-model="currentItem.despesa" validate-on-blur :items="despesaValues" item-value="id"
          :item-text="despesaText" return-object outlined dense >
          <template v-slot:label>
            <span>Código da Despesa - Descrição <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete v-model="currentItem.tipoguia" validate-on-blur  :items="tipoguiaValues" item-value="id" 
          :item-text="tipoguiaText" return-object outlined dense >
          <template v-slot:label>
            <span>Codigo do Tipo de Guia - Descrição <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete> 
      </v-col>

      <v-col>
        <v-autocomplete v-model="currentItem.xmlagrupado" validate-on-blur  :items="xmlagrupadoValues" item-value="id" 
          :item-text="xmlagrupadoText" return-object outlined dense >
          <template v-slot:label>
            <span>Forma de geração no XML (Procedimento) <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete> 
      </v-col>
      <v-col>
        <v-autocomplete v-model="currentItem.unidade_medida" validate-on-blur  :items="unidade" item-value="id" 
          :item-text="unidadeText" return-object outlined dense >
          <template v-slot:label>
            <span>Unidade de Medida <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete> 
      </v-col>






      </v-row>
      <v-row dense>
        <v-col>
          <v-text-field label="Quantidade" outlined v-model="currentItem.quantidade_matmed" type="Number">
          </v-text-field>
        </v-col> 
        <v-col>
          <vuetify-money outlined label="Valor cobrança" v-model="currentItem.valor_cobranca_matmed" :options="options" >
          </vuetify-money>
        </v-col>
        <v-col>
          <v-text-field label="Desconto (%)" outlined v-model="currentItem.desconto_matmed" >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Acréscimo (%)" outlined v-model="currentItem.acrescimo_matmed">
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Total" outlined v-model="procedureTotalValue" disabled></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="text-end">
          <ConfirmButton color="success" :loading="loading">
            Salvar
          </ConfirmButton>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
  
<script>
import ConfirmButton from '../../ConfirmButton.vue';
import UtilsFunc from '../../../service/utilsFunc';
import '../../../assets/css/styles.css';
import api from '../../../http';

const { withCRUDUtils} = UtilsFunc

export default {
  name: 'ProcedureFormmatmed',
  components: { ConfirmButton },
  props: {
    onSubmit: Function,
    frequencies: Array,
    statusOptions: Array,
    ativoOptions: Array,
    loading: Boolean,
    currentItem: {type:Object,default:() => {
      return { 
        tabela:{nome:''},  
        despesa:{nome:''},
        tipoguia:{nome:''},
        xmlagrupado:{nome:''},
        unidade_medida:{nome:''},
      }
      }},
  },
  data: () => withCRUDUtils({
    options: {
      locale: "pt-BR",
      prefix: "R$",
      suffix: "",
      length: 11,
      precision: 2
    },
    currencyMask: {
      decimal: ',',
      thousands: '.',
      precision: 2,
    },
    requiredField: [e => e !== undefined  && e !== null && e!== '' || 'Obrigatório'],
    despesaValues: [],
    tipoguiaValues: [], 
    xmlagrupadoValues: [],
    unidade: [],
    tableValues: [],
  }),
  methods: {
    async getTableValues() {
      const LOADING_NAME = 'get:tableValues'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get('operadoras/tabelas/')
        this.tableValues = data.filter(tabela => tabela.ativo)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async getDespesaValues() {
      const LOADING_NAME = 'get:despesaValues'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get('operadoras/despesas/')
        this.despesaValues = data.filter(despesa => despesa.ativo)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async getTipoguiaValues() {
      const LOADING_NAME = 'get:tipoguiaValues'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get('operadoras/tipoguia/')

        this.tipoguiaValues = data.filter(tipoguia => tipoguia.ativo)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async getXmlagrupadoValues() {
      const LOADING_NAME = 'get:xmlagrupadoValues'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get('operadoras/xmlagrupado/')
        this.xmlagrupadoValues = data.filter(xmlagrupado => xmlagrupado.ativo)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async getUnidadeMedida() {
      const LOADING_NAME = 'get:unidade'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get('uteis/unidade/')
        this.unidade = data.filter(item => item.ativo)
        // console.log(this.unidade)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    onBeforeSubmit() {
      const validation = this.$refs.formRef.validate()
      if (!validation) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios!");
        return;
      }
      // Função para substituir vírgula por ponto e garantir duas casas decimais
      const formatNumber = (value) => {
      if (value === null || value === undefined || value === '') {
        return 0;
      }
      if (typeof value === 'string') {
        value = value.replace(',', '.');
        return parseFloat(value).toFixed(2);
      }
      return value;
    };

      // Formatar os valores de desconto e acréscimo
      const desconto = formatNumber(this.currentItem.desconto_matmed);
      const acrescimo = formatNumber(this.currentItem.acrescimo_matmed);

      let valorCobrancaFinal = this.procedureTotalValue;

      // Verificar se valorCobrancaFinal é uma string antes de chamar replace
      if (typeof valorCobrancaFinal === 'string') {
        valorCobrancaFinal = parseFloat(valorCobrancaFinal.replace('R$', '').replace('.', '').replace(',', '.'));
      } else if (typeof valorCobrancaFinal === 'number') {
        valorCobrancaFinal = parseFloat(valorCobrancaFinal.toFixed(2)); // Certificar-se de que é um número com 2 casas decimais
      } else {
        valorCobrancaFinal = 0; // Definir um valor padrão em caso de tipo inesperado
      }

      const frequenciaId = this.currentItem?.frequencia?.id

      const fields = {
        id: this.currentItem.id,
        ativo: Boolean(this.currentItem.ativo),
        data_inicio_matmed: (this.currentItem.data_inicio_matmed),
        data_fim_matmed: (this.currentItem.data_fim_matmed),
        quantidade_matmed: Number(this.currentItem.quantidade_matmed),
        desconto_matmed: desconto,
        acrescimo_matmed: acrescimo,
        valor_cobranca_matmed: this.currentItem.valor_cobranca_matmed,
        valor_cobranca_final_matmed: valorCobrancaFinal,
        tabela: this.currentItem.tabela?.id,
        despesa: this.currentItem.despesa?.id,
        tipoguia: this.currentItem.tipoguia?.id,
        xmlagrupado: this.currentItem.xmlagrupado?.id,
        unidade_medida: this.currentItem.unidade_medida?.id,
        frequencia: frequenciaId || this.currentItem.frequencia,
        codigo: this.currentItem.codigo,
      }
      if (this.currentItem.aditivo_matmed) {
        fields.nr_aditivo = this.currentItem.nr_aditivo;
        fields.data_aditivo = this.currentItem.data_aditivo;
      }

      // console.log('Enviando --> ', fields)
      this.onSubmit(fields)
    },
    calculateQuantity() {
        const startDate = new Date(this.currentItem.data_inicio_matmed.split('T')[0]);
        const endDate = new Date(this.currentItem.data_fim_matmed.split('T')[0]);
        if (!isNaN(startDate) && !isNaN(endDate)) {
            // Calcula a diferença em dias entre as duas datas
            const timeDiff = endDate.getTime() - startDate.getTime();
            const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
            this.currentItem.quantidade_matmed = diffDays;
        } else {
            this.currentItem.quantidade_matmed = 0;
        }
    }
  },
  computed: {
    formattedDataInicio: {
      get() {
        return this.currentItem.data_inicio_matmed ? this.currentItem.data_inicio_matmed.split('T')[0] : '';
      },
      set(newValue) {
        this.currentItem.data_inicio_matmed = newValue;
      }
    },
    formattedDataFim: {
      get() {
        return this.currentItem.data_fim_matmed ? this.currentItem.data_fim_matmed.split('T')[0] : '';
      },
      set(newValue) {
        this.currentItem.data_fim_matmed = newValue;
      }
    },
    procedureTotalValue() {
     const basePrice = parseFloat(this.currentItem.valor_cobranca_matmed);
      const discount = this.currentItem.desconto_matmed ? parseFloat(this.currentItem.desconto_matmed) / 100 : 0;
      const addition = this.currentItem.acrescimo_matmed ? parseFloat(this.currentItem.acrescimo_matmed) / 100 : 0;
      const adjustedPrice = (basePrice * (1 + addition - discount)) * this.currentItem.quantidade_matmed;
      return `R$ ${adjustedPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    },
     tipoguiaText() {
      return (item) => {
          return `${item.tipoguia} - ${item.nome}`;
      }
    },
    tabelaText() {
      return (item) => {
          return `${item.tabela} - ${item.nome}`;
      }
    },
    despesaText() {
      return (item) => {
          return `${item.despesa} - ${item.nome}`;
      }
    },
    xmlagrupadoText() {
      return (item) => {
          return ` ${item.nome}`;
      }
    },
    unidadeText() {
      return (item) => {
          return `${item.codigo} - ${item.nome}`;
      }
    },
  },
   watch: {
    'currentItem.data_inicio_matmed': 'calculateQuantity',
    'currentItem.data_fim_matmed': 'calculateQuantity'
  },
  mounted() {
   
    this.getTableValues()
    this.getDespesaValues()
    this.getTipoguiaValues()
    this.getXmlagrupadoValues()
    this.getUnidadeMedida()
  },
}
</script>
  
<style scoped>
.center-text {
  text-align: center;
  font-size: 20px;
}
</style>
